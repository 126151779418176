import type React from "react";
import { QueryClient, QueryClientProvider, QueryCache } from "@tanstack/react-query";
import { mtuDefaultTheme as theme } from "@fas/ui-themes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import {
  addNotification,
  getNotificationsList,
} from "@fas/ui-framework";
import { store } from "./modules/Affiliates/store";

const getErrorNotification = (e: Error) => ({
  message: "errorMessage" in e && typeof e.errorMessage === "string" ? e.errorMessage : "Something went wrong...",
  severity: "error",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (e, query) => {
      const getNotification = typeof query.meta?.getErrorNotification === "function" ? query.meta?.getErrorNotification : getErrorNotification;
      const notification = getNotification(e);
      if (notification) {
        const notifications = getNotificationsList(store.getState());
        if (notifications.every(({ message }) => message !== notification.message)) {
          store.dispatch(addNotification(notification));
        }
      }
    },
  }),
});

const overrideTheme = {
  ...theme,
  components: {
    ...theme.components,
    MuiAlert: {
      ...theme.components?.MuiAlert,
      styleOverrides: {
        ...theme.components?.MuiAlert.styleOverrides,
        root: {
          ...theme.components?.MuiAlert.styleOverrides?.root,
          "[role=\"presentation\"] &": {
            maxWidth: "calc(100vw - (100vw * 0.08))",
          },
          textAlign: "left",
          textWrap: "pretty",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        ...theme.components.MuiOutlinedInput,
        root: {
          ...theme.components.MuiOutlinedInput.root,
          "&.Mui-disabled": {
            backgroundColor: "#D4D9E6",
            color: "#8D96AE",
            opacity: 0.5,
          },
          "&.Mui-readOnly": {
            backgroundColor: "#f6f8ff",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabled,
              borderWidth: 1,
            },
          },
        },
      },
    },
  },
};

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={overrideTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}
