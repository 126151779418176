import type React from "react";
import {
  CircularProgress, Fade, LinearProgress,
} from "@mui/material";
import { Error } from "@fas/ui-core";

type LoadingProps = {
  isLoading: boolean;
  isRefetching?: boolean;
  hasError: boolean;
  children?: React.ReactNode;
  ErrorComponent?: React.FC;
  LoadingComponent?: React.FC;
  RefetchingComponent?: React.FC<{isRefetching: boolean}>;
};

export const Loading: React.FC<LoadingProps> = ({
  isRefetching,
  isLoading,
  hasError,
  children,
  ErrorComponent,
  LoadingComponent,
  RefetchingComponent = RefetchComponent,
}) => {
  if (isLoading) return LoadingComponent ? <LoadingComponent /> : <CircularProgress sx={{ m: "auto" }} />;
  if (hasError) return ErrorComponent ? <ErrorComponent /> : <Error />;
  return (
    <>
      {isRefetching !== undefined && <RefetchingComponent isRefetching={isRefetching} />}
      {children}
    </>
  );
};

export default Loading;

function RefetchComponent({ isRefetching }: { isRefetching: boolean }) {
  return <Fade in={isRefetching}><LinearProgress sx={{ mt: -0.5 }} /></Fade>;
}
