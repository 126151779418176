/* eslint-disable import/max-dependencies */
import {
  TableProps, useTable,
} from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import {
  getOneAffiliateApplications,
  getOneMyAffiliateApplications,
} from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import { SEARCH_INT_APPLICATIONS_TABLE_KEY } from "../../../../helpers/constants/affiliates";
import useTenantId from "../../useTenantId";
import { ok, OkResponse } from "../../../../services/api";
import type { ArrayElement } from "../../types";
import { userService } from "../../../../services/user";
import useTableQueryFilter from "../../utils/useTableQueryFilter";
import filter from "../../utils/filter";

export type FiltersType = {
  affiliateId?: string,
  status?: string,
  isExt?: string,
}

export const defaultApplicationIntFilters: FiltersType = {
  affiliateId: "",
  status: "",
  isExt: "",
};

export default function useSearchIntApplications() {
  type TData = ArrayElement<OkResponse<typeof getOneAffiliateApplications>["applications"]>;
  const tableProps = useTable<TData, FiltersType>(SEARCH_INT_APPLICATIONS_TABLE_KEY);
  const affiliateId = (tableProps.filters.affiliateId || "").trim();
  const enabled = affiliateId?.length > 4;
  const tenantId = useTenantId();
  const havePermissionToAllAffiliate = userService.can(["getOneAffiliateApplications"]);
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery({
    queryKey: [SEARCH_INT_APPLICATIONS_TABLE_KEY, tenantId, affiliateId],

    queryFn: async () => {
      if (havePermissionToAllAffiliate) {
        return ok(getOneAffiliateApplications(tenantId, affiliateId));
      }
      const res = await ok(getOneMyAffiliateApplications(tenantId, affiliateId));

      return {
        applications: res,
      };
    },

    enabled,
  });

  useTableQueryFilter(SEARCH_INT_APPLICATIONS_TABLE_KEY, defaultApplicationIntFilters);

  return {
    ...tableProps,
    isDataAvailable: !!data?.applications.length,
    data: enabled ? filter(data?.applications || [], {
      status: tableProps.filters.status,
      isExt: tableProps.filters.isExt,
    }) : [],
    isLoading: isLoading && enabled,
    isRefetching,
  } as TableProps<TData, FiltersType> & {
    isRefetching: boolean,
    isDataAvailable: boolean,
  };
}
