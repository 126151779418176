/* eslint-disable import/max-dependencies */
import {
  changeTableItemsTotalAmount,
  setTableData,
  setTableSelection,
  type TableProps,
  useTable,
} from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { isEqual } from "lodash";
import { findAndCountInvalidDomains } from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import { useDispatch } from "../../store";
import { ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY } from "../../../../helpers/constants/affiliates";
import useTenantId from "../../useTenantId";
import { ok } from "../../../../services/api";
import type { InvalidDomainType, FiltersType } from "./types";
import useInvalidateOperation from "../../utils/useInvalidateOperation";
import useTableQueryFilter from "../../utils/useTableQueryFilter";

export const defaultAdvertiserDomainValidationFilters: FiltersType = {
  domain: "",
  advertiserName: "",
};

export default function useAdvertiserDomainValidation() {
  const dispatch = useDispatch();
  const tableProps = useTable<InvalidDomainType, FiltersType>(ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY);
  const tenantId = useTenantId<"adv">();
  const enabled = !useTableQueryFilter(
    ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY,
    defaultAdvertiserDomainValidationFilters
  );
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery({
    queryKey: [tenantId, "findAndCountInvalidDomains", tableProps.filters],
    queryFn: () => ok(findAndCountInvalidDomains(tenantId, {
      domain: tableProps.filters.domain?.trim() || undefined,
      advertiserName: tableProps.filters.advertiserName || undefined,
    })),

    enabled,
  });

  useEffect(() => {
    if (data) {
      dispatch(setTableData(ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY, data.data));
      dispatch(changeTableItemsTotalAmount(ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY, data.totalCount));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const currentData = tableProps.data as InvalidDomainType[];
    const selected = tableProps.selected as InvalidDomainType[];

    const visibleSelected = selected.filter((
      selectItem
    ) => currentData.some((item) => isEqualAdvertiserDomain(item, selectItem)));

    if (!isEqual(visibleSelected, selected)) {
      dispatch(setTableSelection(ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY, visibleSelected));
    }
  }, [dispatch, tableProps.data, tableProps.selected]);

  const onDeselect = useCallback(// todo need pass to config or change quality rows find to delete
    (item: InvalidDomainType) => dispatch(
      setTableSelection(ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY, tableProps.selected
        .filter((selectedItem) => !isEqualAdvertiserDomain(item, selectedItem)))
    ),
    [dispatch, tableProps.selected]
  );
  return {
    ...tableProps,
    isLoading,
    isRefetching,
    onDeselect,
  } as TableProps<InvalidDomainType, FiltersType> & { isRefetching: boolean };
}

export const getId = (a?: InvalidDomainType | null) => `${a?.domain}-${a?.advertiserHash}`;
export const isEqualAdvertiserDomain = (a: InvalidDomainType, b: InvalidDomainType) => getId(a) === getId(b);
export const useInvalidateQuery = () => {
  const invalidateQuery = useInvalidateOperation();
  return () => invalidateQuery(["findAndCountInvalidDomains", "getAllAvailablePartnersWithInvalidDomains"]);
};
