import { getTableFilters } from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import { getAffiliateExtRates } from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import useTenantId from "../useTenantId";
import { useSelector } from "../store";
import { EXT_RATE_AFFILIATES_TABLE_KEY } from "../../../helpers/constants/affiliates";
import { ok } from "../../../services/api";
import useAffiliateId from "../ProfileAffiliate/useAffiliateId";
import type { ExtRateType, FiltersType } from "./types";
import useExtRateQueryKey from "./useExtRateQueryKey";

export function getExtRateId(item?: ExtRateType | null) {
  if (!item) return "";
  return `${item.affiliateId}-${item.offerId}`;
}

export function isEqualExtRate(a?: ExtRateType, b?: ExtRateType) {
  return !!a && !!b && getExtRateId(a) === getExtRateId(b);
}

function useExtRate(enabled: boolean) {
  const tenantId = useTenantId();
  const affiliateId = useAffiliateId();
  const filters = useSelector((
    state
  ) => getTableFilters<FiltersType>(state, EXT_RATE_AFFILIATES_TABLE_KEY));
  const queryKey = useExtRateQueryKey();
  return useQuery({
    queryKey,
    queryFn: async () => {
      const queryParams = {
        search: filters.search?.trim() || undefined,
      };

      return ok(getAffiliateExtRates(tenantId, affiliateId, {
        ...queryParams,
      }));
    },
    enabled,
  });
}

export default useExtRate;
