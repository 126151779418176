/* eslint-disable import/max-dependencies */
import {
  TableProps, useTable,
} from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import { getExtManagerOneAffiliateAllApplications } from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import {
  SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY,
} from "../../../../helpers/constants/affiliates";
import useTenantId from "../../useTenantId";
import { ok, OkResponse } from "../../../../services/api";
import type { ArrayElement } from "../../types";
import useTableQueryFilter from "../../utils/useTableQueryFilter";
import filter from "../../utils/filter";

export type FiltersType = {
  dataProviderName: string,
  offerId?: string,
  affiliateId?: string,
  status?: "Approved" | "Declined" | "Pending" | "Stopped",
}

export const defaultTLApplicationAffiliateExtFilters: FiltersType = {
  affiliateId: "",
  dataProviderName: "",
  offerId: "",
  status: undefined,
};

export default function useSearchExtAffiliateApplications() {
  type TData = ArrayElement<OkResponse<typeof getExtManagerOneAffiliateAllApplications>>;
  const tableProps = useTable<TData, FiltersType>(SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY);
  const { filters: { status, ...filters } } = tableProps;
  const enabled = (filters.affiliateId || "").trim().length > 4;
  const tenantId = useTenantId();
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery({
    queryKey: [SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY, tenantId, filters],
    queryFn: async () => ok(getExtManagerOneAffiliateAllApplications(tenantId, filters.affiliateId || "", {
      dataProviderName: filters.dataProviderName || undefined,
      offerId: filters.offerId || undefined,
    })),
    enabled,
  });

  useTableQueryFilter(SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY, defaultTLApplicationAffiliateExtFilters);

  return {
    ...tableProps,
    isDataAvailable: !!data?.length,
    data: enabled ? filter(data || [], { status }) : [],
    isLoading: isLoading && enabled,
    isRefetching,
  } as TableProps<TData, FiltersType> & {
    isRefetching: boolean,
    isDataAvailable: boolean,
  };
}
