/* eslint-disable import/max-dependencies */
import {
  TableProps, useTable,
} from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import { getExtManagerAllApplications } from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import {
  SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY,
} from "../../../../helpers/constants/affiliates";
import useTenantId from "../../useTenantId";
import { ok, OkResponse } from "../../../../services/api";
import type { ArrayElement } from "../../types";
import useTableQueryFilter from "../../utils/useTableQueryFilter";
import filter from "../../utils/filter";

export type FiltersType = {
  dataProviderName: string,
  offerId?: string,
  affiliateId?: string,
  status?: "Approved" | "Declined" | "Pending" | "Stopped",
}

export const defaultTLApplicationExtFilters: FiltersType = {
  dataProviderName: "",
  offerId: "",
  status: undefined,
};

export default function useSearchExtApplications() {
  type TData = ArrayElement<OkResponse<typeof getExtManagerAllApplications>>;
  const tableProps = useTable<TData, FiltersType>(SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY);
  const { filters: { status, ...filters } } = tableProps;
  const enabled = (filters.dataProviderName || "").trim().length > 1;
  const tenantId = useTenantId();
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery({
    queryKey: [SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY, tenantId, filters],
    queryFn: async () => ok(getExtManagerAllApplications(tenantId, filters.dataProviderName || "", {
      offerId: filters.offerId || undefined,
    })),
    enabled,
  });

  useTableQueryFilter(SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY, defaultTLApplicationExtFilters);

  return {
    ...tableProps,
    isDataAvailable: !!data?.length,
    data: enabled ? filter(data || [], { status }) : [],
    isLoading: isLoading && enabled,
    isRefetching,
  } as TableProps<TData, FiltersType> & {
    isRefetching: boolean,
    isDataAvailable: boolean,
  };
}
