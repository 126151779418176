import type { FiltersType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const defaultSubrateFilters: FiltersType = {
  onlyDefault: "true",
  withCapSetting: undefined,
  utmContent: "",
  s3: "",
  offer: null,
  country: "",
  offerSite: "",
  conversionType: "",
  platform: "",
};
