import { useQuery } from "@tanstack/react-query";
import {
  changeTableItemsTotalAmount, getTableFilters,
  setTableData,
  TableProps,
  useTable,
} from "@fas/ui-framework";
import { useEffect } from "react";
import { findAndCountIntOffersAes } from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import useTenantId from "../../useTenantId";
import { ok } from "../../../../services/api";
import type { FiltersType, IntOfferType } from "./types";
import useOffersQueryKey from "./useOffersQueryKey";
import { useDispatch, useSelector } from "../../store";
import { INT_OFFERS_AES_TABLE_KEY } from "../../../../helpers/constants/affiliates";
import useTableQueryFilter from "../../utils/useTableQueryFilter";

export function getOfferId(item?: IntOfferType | null) {
  if (!item) return "";
  return `${item.id}`;
}

export function isOffers(a?: IntOfferType, b?: IntOfferType) {
  return !!a && !!b && getOfferId(a) === getOfferId(b);
}

export const defaultIntOffersAesFilters: FiltersType = {
  offerName: "",
  country: "",
  conversionType: undefined,
  isActive: undefined,
  offerType: undefined,
  platform: undefined,
};

export function isEnabledOfferName(offerName: string) {
  return offerName?.trim()?.length > 2;
}

function useOffers() {
  const dispatch = useDispatch();
  const tableProps = useTable<IntOfferType, FiltersType>(INT_OFFERS_AES_TABLE_KEY);
  const tenantId = useTenantId() as "aes";
  const { offerName, ...filters } = useSelector((
    state
  ) => getTableFilters<FiltersType>(state, INT_OFFERS_AES_TABLE_KEY));

  const queryKey = useOffersQueryKey();
  const enabled = isEnabledOfferName(offerName);
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery({
    queryKey,
    queryFn: async () => ok(findAndCountIntOffersAes(tenantId, offerName?.trim(), {
      country: filters.country || undefined,
      platform: filters.platform,
      conversionType: filters.conversionType,
      isActive: filters.isActive === undefined ? undefined : filters.isActive === "yes",
      offerType: filters.offerType,
    })),
    enabled,
  });

  useEffect(() => {
    if (data) {
      dispatch(setTableData(INT_OFFERS_AES_TABLE_KEY, data.data));
      dispatch(changeTableItemsTotalAmount(INT_OFFERS_AES_TABLE_KEY, data.count));
    }
  }, [data, dispatch]);

  useTableQueryFilter(INT_OFFERS_AES_TABLE_KEY, defaultIntOffersAesFilters);

  return {
    ...tableProps,
    data: enabled ? tableProps.data : [],
    count: enabled ? tableProps.count : 0,
    isLoading: isLoading && enabled,
    isRefetching,
  } as TableProps<IntOfferType, FiltersType> & { isRefetching: boolean };
}

export default useOffers;
