import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Fade, LinearProgress } from "@mui/material";
import useUserMe from "../Affiliates/useUserMe";
import { userService } from "../../services/user";
import useStorageTenantId from "./useStorageTenantId";
import Loading from "./Loading";

type TenantContextType = {
  currentTenant: string;
  tenants: string[];
  setTenant: (tenant: string) => void;
};
const EMPTY_ARRAY: string[] = [];

const TenantContext = createContext<TenantContextType | undefined>(undefined);

function getInitialTenant(storageCurrentTenant: string, allowedTenantIds?: string[]) {
  return allowedTenantIds?.find((id) => id === storageCurrentTenant) || allowedTenantIds?.[0] || "unknown";
}

function TenantBody({ children }: { children?: React.ReactNode }) {
  const { data } = useUserMe();
  const [storageCurrentTenant, setStorageCurrentTenant] = useStorageTenantId();
  const [currentTenant, setCurrentTenant] = useState(getInitialTenant(storageCurrentTenant, data?.allowedTenantIds));
  const [, setDynamicPermissions] = useState(EMPTY_ARRAY);

  const setTenant = useCallback((tenant: string) => {
    const validTenant = data?.allowedTenantIds.includes(tenant) ? tenant : data?.allowedTenantIds[0] || "";
    const operations = data?.allowedTenantOperationIds?.[validTenant] || EMPTY_ARRAY;
    setCurrentTenant(validTenant);
    setStorageCurrentTenant(validTenant);
    setDynamicPermissions(operations);
    userService.setDynamicPermissions(operations);
  }, [data?.allowedTenantIds, data?.allowedTenantOperationIds, setStorageCurrentTenant]);

  useEffect(() => {
    if (data) {
      setTenant(getInitialTenant(storageCurrentTenant, data?.allowedTenantIds));
    }
  }, [data]);
  return (
    <TenantContext.Provider value={{ currentTenant, setTenant, tenants: data?.allowedTenantIds || EMPTY_ARRAY }}>
      {children}
    </TenantContext.Provider>
  );
}

export const TenantProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    isLoading, isRefetching, isError,
  } = useUserMe();

  return (
    <Loading
      isLoading={isLoading}
      isRefetching={isRefetching}
      hasError={isError}
      RefetchingComponent={() => (
        <Fade in={isRefetching}>
          <LinearProgress sx={{
            zIndex: "modal", position: "absolute", left: 0, right: 0,
          }}
          />
        </Fade>
      )}
    >
      <TenantBody>{children}</TenantBody>
    </Loading>
  );
};

export const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error("useTenant must be used within a TenantProvider");
  }
  return context;
};
