import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserMe } from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import { ok } from "../../services/api";

function useUserMe() {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["getUserMe"],
    queryFn: () => ok(getUserMe("unknown")),
    enabled: !queryClient.getQueryData(["getUserMe"]), // Prevents multiple requests
  });
}

export default useUserMe;
