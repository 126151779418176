/* eslint-disable import/max-dependencies */
import React from "react";
import environment from "environment";
import { BrowserRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { useQuery } from "@tanstack/react-query";
import { userApiService } from "./services/userApi";
import AppHeader from "./components/AppHeader";
import "typeface-roboto";
import RouterSlots from "./modules/components/RouterSlots";
import slots from "./slots";
import { TenantProvider } from "./modules/components/TenantContext";
import Providers from "./Providers";
import Loading from "./modules/components/Loading";

const Component = () => {
  const { isLoading: loading, isError } = useQuery({
    queryKey: ["getUser"],
    queryFn: async () => userApiService.getUserInfo(environment.endpoints.get.login),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return (
    <div className="App">
      <DocumentTitle title="CPA Admin Panel">
        <Loading isLoading={loading} hasError={isError}>
          <BrowserRouter>
            <TenantProvider>
              <AppHeader />
              <RouterSlots slots={slots} />
            </TenantProvider>
          </BrowserRouter>
        </Loading>
      </DocumentTitle>
    </div>
  );
};

export default function App() {
  return (
    <Providers>
      <Component />
    </Providers>
  );
}
